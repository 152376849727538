// Score.js

import React from 'react'

//9341 //8419 // 4523 

const Score = ({ score, setScore, 
            setCurrentQuestion, setQuizStarted, 
            setIsLastq, setTimer, target}) => {
    return (
    <div>
        <div className="card-body">
            {
                score == target ?
                <>
                <h4 className="card-text">Your score: {score} / {target}</h4>
                <br/>
                    <h2 className="card-title">Congrats you have unlocked the box!</h2>
                    <br/>
                    <h1 className="card-title text-success">1319</h1>
                    <br/>
                    <button className="btn btn-primary"
                            onClick={() => { setCurrentQuestion(0); 
                                setScore(0); setQuizStarted(true); 
                                setIsLastq(false); setTimer(10);}}>
                                Congrats!
                    </button>
                </>
                :
                <>
                <h4 className="card-text">Your score: {score} / {target}</h4>
                <br/>
                    <h2 className="card-title">Need a perfect score to unlock!</h2>
                    
                    <button className="btn btn-primary"
                            onClick={() => { window.location.reload(); }}>
                                <h3>Try Again</h3>
                    </button>
                </>
            }
        </div>
    </div>
    )
}

export default Score;