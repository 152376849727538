// App.js

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import qBank from "./Components/QuestionBank";
import Questions from './Components/Questions';
import Score from './Components/Score';

var list = [ 1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14 ];

while(
  list.findIndex(function(v, i) {
    return v == i + 1 || (i && Math.abs(list[i - 1] - v) == 1);
  }) != -1
) {
  list.sort(function() { return Math.random() - 0.5; });
}

function shuffle(array) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }

shuffle(qBank["Practice_9_Tables"]);
shuffle(qBank["Test_6_7_9_Tables_8419"]);
shuffle(qBank["Test_6_7_8_9_Tables_4523"]);
shuffle(qBank["Test_6_7_8_9_12_Tables_4523"]);
shuffle(qBank["Test_6_7_8_9_10_11_12_13_H_1_2_3_4_5_Tables_1319"]);
shuffle(qBank["Test_6_7_8_9_10_11_12_13_H_1_2_3_4_5_15_Tables_1319"]);

const App = () => {
const [questions, setQuestions] = useState(qBank["Test_6_7_8_9_10_11_12_13_H_1_2_3_4_5_Tables_1319"]);
const [currentQuestion, setCurrentQuestion] = useState(0);
const [score, setScore] = useState(0);
const [numpad, setNumpad] = useState('');
// set time for each question
const [timer, setTimer] = useState(10); 
const [quizStarted, setQuizStarted] = useState(false);
const [isLastq, setIsLastq]=useState(false)
const [feedback, setFeedback] = useState("");
const [allfeedback, setAllfeedback] = useState("");
const [challenge,setChallenge] = useState("Test_6_7_8_9_10_11_12_13_H_1_2_3_4_5_Tables");
const [oneortwo,setOneortwo] = useState(1);

let target = questions.length;
/*
useEffect(()=>{
    //Run Once
    console.log(Questions)
    console.log("Iwas called")
    shuffle(Questions);
    console.log(Questions)
},[])
*/
useEffect(() => {
    if (quizStarted) {
    const interval = setInterval(() => {
        setTimer(prevTimer => {
        if (prevTimer > 0) {
            return prevTimer - 1;
        } else {
            /*setCurrentQuestion(prevQuestion => prevQuestion + 1);
            // Reset timer for the next question
            return 10;*/
            return prevTimer - 1;
        }
        });
    }, 1000);
    
    return () => clearInterval(interval);
    }
}, [currentQuestion, quizStarted]);

const handleAnswerClick = (selectedAnswer) => {
    if(questions[currentQuestion].type == "mcq"){
        if (selectedAnswer === questions[currentQuestion].answer) {
            setScore(prevScore => prevScore + 1);
        }
    }
};


const handleNumPadClick = (selectedNumber) => {
    let mynum = numpad + selectedNumber + '';
    setNumpad(mynum);

    if(questions[currentQuestion].type == "math-number"){
        if(questions[currentQuestion].answer >= 10 && mynum < 10){
            //Do Nothing
        }
        else if(questions[currentQuestion].answer >= 100 && mynum < 100){

        }
        else{
            if(questions[currentQuestion].type == "math-number"){
                let rndInt = Math.floor(Math.random() * 2) + 1
                setOneortwo(rndInt);

                console.log(mynum)
                console.log(questions[currentQuestion].answer)
                console.log(mynum == questions[currentQuestion].answer)
                if (mynum == questions[currentQuestion].answer && timer > 0) {
                    setScore(prevScore => prevScore + 1);
                    if (currentQuestion + 2 === questions.length) {
                        setIsLastq(true)
                    }
                    setCurrentQuestion(prevQuestion => prevQuestion + 1);
                    setNumpad('');
                    setTimer(10);
                }
                else if(mynum == questions[currentQuestion].answer && timer < 0){
                    if (currentQuestion + 2 === questions.length) {
                        setIsLastq(true)
                    }
                    setCurrentQuestion(prevQuestion => prevQuestion + 1);
                    setNumpad('');
                    setTimer(10);
                }
                else{
                    setFeedback("Incorrect! " + questions[currentQuestion].question + questions[currentQuestion].answer);
                    setAllfeedback(allfeedback+"<br>"+questions[currentQuestion].question + questions[currentQuestion].answer);
                    setNumpad('');
                }
            }

        }
    }
};

const handleNumPadClear = () => {
    setNumpad('');
}

const handleNextQuestion=()=>{
    if(questions[currentQuestion].type == "math-number"){
        console.log(numpad)
        console.log(questions[currentQuestion].answer)
        console.log(numpad == questions[currentQuestion].answer)
        if (numpad == questions[currentQuestion].answer && timer > 0) {
            setScore(prevScore => prevScore + 1);
        }
        else{
            setFeedback("Incorrect! " + questions[currentQuestion].question + questions[currentQuestion].answer);
            setAllfeedback(allfeedback+"<br>"+questions[currentQuestion].question + questions[currentQuestion].answer);
        }
    }
    if (currentQuestion + 2 === questions.length) {
        setIsLastq(true)
    }
    setCurrentQuestion(prevQuestion => prevQuestion + 1);
    setNumpad('');
    setTimer(10);
}

const startQuiz = () => {
    setQuizStarted(true);
};

return (

    <div className="flex-container">
    <div className="card container mt-2 
        d-flex justify-content-center 
        align-items-center flex-child"
        style={{ maxWidth: '1000px', margin: '0 auto' }}>
             {quizStarted ?  <></> :  <h1 className="text-success mt-2">Unlock Me!</h1> }
             {quizStarted ?  <></> :  <h3 className=" mt-2">Challenge: {challenge}</h3> }

    <div className="full-width">
        
        {!quizStarted ? (
        <div>
            <div className="card-body">
                
                <button className="btn btn-primary"
                onClick={startQuiz}>
                <h3>Start Test</h3>
                </button>
            </div>
        </div>
        ) : currentQuestion < questions.length ? (
        <Questions
            questions={questions}
            handleNextQuestion={handleNextQuestion}
            currentQuestion={currentQuestion}
            handleAnswerClick={handleAnswerClick}
            handleNumPadClick={handleNumPadClick}
            handleNumPadClear={handleNumPadClear}
            numpad={numpad}
            timer={timer}
            isLastq={isLastq}
            oneortwo={oneortwo}
        />
        ) : (
        <Score
            score={score}
            setScore={setScore}
            setCurrentQuestion={setCurrentQuestion}
            setQuizStarted={setQuizStarted}
            setIsLastq={setIsLastq}
            setTimer={setTimer}   
            target={target}      
        />
        )}
    </div>
    </div>

    <div className="flex-child-2">

    <div>
        {feedback !== "" ?     
        <div class="alert alert-danger" role="alert">
    {feedback}
        </div> : <></>}

    </div>
    <div>
    {
    allfeedback !== "" ?  
        <div>
            Revise the following: <div dangerouslySetInnerHTML={{ __html: allfeedback }} />
        </div>
        :
        <></>
    }

    </div>

    </div>


    </div>
);
};

export default App;