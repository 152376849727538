// Questions.js

import React, { useState } from 'react';

const Questions = ({ questions, 
                    handleNextQuestion, 
                    currentQuestion, 
                    handleAnswerClick, 
                    handleNumPadClick,
                    handleNumPadClear,
                    numpad,
                    timer, 
                    isLastq,
                    oneortwo
                 }) => {
    const optionIds = ['A', 'B', 'C', 'D'];
    const [selectedOption, setSelectedOption] = useState(null);
    
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        handleAnswerClick(option);
    };
    /*
    <span class="badge text-bg-primary">Primary</span>
    <span class="badge text-bg-secondary">Secondary</span>
    <span class="badge text-bg-success">Success</span>
    <span class="badge text-bg-danger">Danger</span>
    <span class="badge text-bg-warning">Warning</span>
    <span class="badge text-bg-info">Info</span>
    <span class="badge text-bg-light">Light</span>
    <span class="badge text-bg-dark">Dark</span>
    */
    return (
        <div className="container mt-3 bg-light">
            <div>
                <div className="card-body">
                    <p className="mt-2 text-dark">

                    Time remaining: <b className={timer > 5 ? "text-warning" : "text-danger blink_me"} style={{"fontSize": "24px"}}>{timer} </b> 
                    {timer < 0 ? <span class="badge text-bg-danger"> Time Up!</span>: <></>}
                    <div className="w3-light-grey">
                        <div className="w3-green" style={{"height":"24px","width": timer > 0 ? `${timer * 10}%` : 0}}></div>
                    </div>
                    </p>
                    {
                        questions[currentQuestion].type == 'mcq' ?
                        <>
                                    <h1 className="small-nice-text">Question {currentQuestion + 1} of {questions.length}                             
                                    { isLastq?(
                            <button className="btn btn-primary btn-sm"
                                    onClick={handleNextQuestion}>
                                    &nbsp;<h3>Submit</h3>
                            </button>

                            ):(
                            <button className="btn btn-danger btn-sm"
                                    onClick={handleNextQuestion}>
                                    &nbsp;<h3>Next Question</h3>
                            </button>
                            )}</h1>
                            <h4 className="card-text">
                            { questions[currentQuestion].id}{') '}
                            { questions[currentQuestion].question}
                            </h4>
                            <div className="list-group">
                                { 
                                questions[currentQuestion].options
                                                            .map((option, index) => (
                                    <button
                                        key={index}
                                        className= { `list-group-item 
                                            list-group-item-action 
                                            mt-2 ${ 
                                                selectedOption === option ? 'active' : ''
                                            }`}
                                            onClick={() => handleOptionClick(option)}
                                            style={{ 
                                            backgroundColor: 
                                            selectedOption === option ? 
                                                'lightblue' : 'white', 
                                            border: '1px solid gray' }}
                                    >
                                        {optionIds[index]}{')'} {option}
                                    </button>
                                ))}
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <h1 className="card-text">
                                
                                <center>
                                    <h1 className="small-nice-text">Question {currentQuestion + 1} of {questions.length}                             
                                    { isLastq?(
                            <button className="btn btn-primary btn-sm"
                                    onClick={handleNextQuestion}>
                                    &nbsp;<h3>Submit</h3>
                            </button>

                            ):(
                            <button className="btn btn-danger btn-sm"
                                    onClick={handleNextQuestion}>
                                    &nbsp;<h3>Skip Question</h3>
                            </button>
                            )}</h1>
                                    <div className="btn-group me-2 button big-button blue-glass" role="group" aria-label="Second group">
                                        <h1 className="red-text-shadows">{oneortwo === 1 ? questions[currentQuestion].question_segment_1 : questions[currentQuestion].question_segment_3}</h1>
                                    </div>
                                    <div className="btn-group me-2 button big-button blue-glass" role="group" aria-label="Second group">
                                        <h1 className="red-text-shadows">{questions[currentQuestion].question_segment_2}</h1>
                                    </div>
                                    <div className="btn-group me-2 button big-button blue-glass" role="group" aria-label="Second group">
                                        <h1 className="red-text-shadows">{oneortwo === 1 ? questions[currentQuestion].question_segment_3 : questions[currentQuestion].question_segment_1}</h1>
                                    </div>
                                    <div className="btn-group me-2 button big-button blue-glass" role="group" aria-label="Second group">
                                        <h1 className="red-text-shadows">{questions[currentQuestion].question_segment_4}</h1>
                                    </div>
                                    <div className="btn-group me-2 button big-button blue-glass" role="group" aria-label="Second group">
                                        <h1 className="red-text-shadows">{numpad}</h1>
                                    </div>
                                </center>
                                
                                </h1>
                                <center><h1 className="small-nice-text">KEYPAD</h1></center>
                                <div className="container" role="toolbar" aria-label="Toolbar with button groups">
                                <center>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(1)}
                                    >
                                        <span></span>1
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(2)}
                                    >
                                        <span></span>2
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(3)}
                                    >
                                        <span></span>3
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(4)}
                                    >
                                        <span></span>4
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(5)}
                                    >
                                        <span></span>5
                                    </button>
                                </center>
                                </div>
                                    <br/>
                                    <div className="container" role="toolbar" aria-label="Toolbar with button groups">
                                    <center>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(6)}
                                    >
                                        <span></span>6
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(7)}
                                    >
                                        <span></span>7
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(8)}
                                    >
                                        <span></span>8
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(9)}
                                    >
                                        <span></span>9
                                    </button>
                                    <button 
                                        className="btn-group me-2 button big-button nice-text blue-glass" 
                                        onClick={() => handleNumPadClick(0)}
                                    >
                                        <span></span>0
                                    </button>
                                    </center>
                                    </div>
                                    <br/>
                                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                        <button className="btn-group me-2 button wide-button small-nice-text red-glass" onClick={() => handleNumPadClear()}>Clear</button>

                                    </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
};

export default Questions;